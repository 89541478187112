
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/view/content/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-5",
  components: {
    Multiselect,
    CodeHighlighter2
  },
  setup() {
    const example5 = ref({
      value: null,
      placeholder: "Select your character",
      label: "name",
      options: [
        {
          value: "captainamerica",
          name: "Captain America",
          icon:
            "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/03_-_Captain_America_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png"
        },
        {
          value: "spiderman",
          name: "Spiderman",
          icon:
            "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/12_-_Spiderman_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png"
        },
        {
          value: "ironman",
          name: "Iron Man",
          icon:
            "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/02_-_IRONMAN_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png"
        }
      ]
    });

    const htmlCode = `<Multiselect
          v-model="example5.value"
          v-bind="example5"
      >
        <template v-slot:singlelabel="{ value }">
          <div class="multiselect-single-label">
            <img class="character-label-icon" :src="value.icon"> {{ value.name }}
          </div>
        </template>

        <template v-slot:option="{ option }">
          <img class="character-option-icon" :src="option.icon"> {{ option.name }}
        </template>
      </Multiselect>`;

    const jsCode = `const example5 = ref({
      value: null,
      placeholder: "Select your character",
      label: "name",
      options: [
        {
          value: "captainamerica",
          name: "Captain America",
          icon:
            "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/03_-_Captain_America_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png"
        },
        {
          value: "spiderman",
          name: "Spiderman",
          icon:
            "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/12_-_Spiderman_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png"
        },
        {
          value: "ironman",
          name: "Iron Man",
          icon:
            "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/02_-_IRONMAN_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png"
        }
      ]
    });`;

    return {
      example5,
      htmlCode,
      jsCode
    };
  }
});
