
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/view/content/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-6",
  components: {
    Multiselect,
    CodeHighlighter2
  },
  setup() {
    const example6 = ref({
      mode: "multiple",
      value: null,
      placeholder: "Select your characters",
      options: {
        batman: "Batman",
        robin: "Robin",
        joker: "Joker"
      }
    });

    const htmlCode = `<Multiselect
      v-model="example6.value"
      v-bind="example6"
    >
      <template v-slot:multiplelabel="{ values }">
        <div class="multiselect-multiple-label">
          {{ values.length }} characters selected
        </div>
      </template>
    </Multiselect>`;

    const jsCode = `const example6 = ref({
      mode: "multiple",
      value: null,
      placeholder: "Select your characters",
      options: {
        batman: "Batman",
        robin: "Robin",
        joker: "Joker"
      }
    });`;

    return {
      example6,
      htmlCode,
      jsCode
    };
  }
});
